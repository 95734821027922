<template>
  <div class="p-4 pb-8 bg-white rounded-lg">
    <div class="mb-7 mx-10 mt-6 d-flex align-center justify-space-between">
      <datatable-detail class="col-6" :table_properties="tableProperties" />

      <div class="col-6 justify-content-end px-4">
        <button
          class="btn btn--export-filter float-end"
          @click.prevent="submitRole"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-cloud-upload</v-icon>
          </span>
          Submit
        </button>
      </div>
    </div>

    <div
      class="custom-container-height w-100 mx-0 pb-3 px-0 px-sm-2 px-md-8 d-flex flex-wrap poppins"
    >
      <NestedDataTable
        tableName="roles"
        editType="role"
        :handleGetFunction="handleRoleSelect"
        :handleCreateRole="handleCreateRole"
      />
      <NestedDataTable
        tableName="pages"
        editType="page"
        :handleGetFunction="handlePageSelect"
      />
      <NestedDataTable
        tableName="actions"
        editType="action"
        :handleGetFunction="handleActionSelect"
      />
      <NestedDataTable tableName="columns" editType="column" />
    </div>
    <CreateRole ref="createRole" />
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import rolesNestedDataMixin from "@/own/mixins/rolesNestedDataMixin";
import NestedDataTable from "@/own/components/roles/NestedDatatable.vue";
import CreateRole from "@/own/components/roles/CreateRole.vue";

export default {
  name: "RolesExample",
  components: {
    NestedDataTable,
    CreateRole,
    DatatableDetail,
  },
  mixins: [rolesNestedDataMixin],
  beforeCreate() {
    setTimeout(() => {
      this.$store.commit(SET_PAGE_LOADING, true);
    }, 200);
  },
};
</script>
